import React, {useEffect, useState} from 'react';
import {FrenchRepublicanCalendar} from "./models/FrenchRepublicanCalendar";
import {FrenchRepublicanDateCard} from "./components/FrenchRepublicanDateCard";
import {createTheme, Skeleton, ThemeProvider} from "@mui/material";
import {blue, red} from "@mui/material/colors";
import {useLocation} from 'react-router-dom'
import { DateTime } from "luxon";

const theme = createTheme({
        palette: {
            primary: {
                main: red["700"]
            },
            secondary: {
                main: blue["700"]
            }
        },
        typography: {
            fontFamily: "Marcellus SC"
        }
    }
);

function App() {

    const [date, setDate] = useState<DateTime>(DateTime.now())
    const search = useLocation().search;
    const dateParam = new URLSearchParams(search).get('date');

    useEffect(() => {
        try {
            const requestedDate = dateParam && (DateTime.fromISO(dateParam))
            if (requestedDate && requestedDate.isValid && !requestedDate.equals(date)) {
                setDate(requestedDate)
            }
        } catch (e) {
            console.log(`Invalid date string: ${dateParam}`)
        }
    }, [date, dateParam])


    const calendar = new FrenchRepublicanCalendar();
    if (date) {
        const republicanDate = calendar.calendarDate(date.toJSDate());
        const dateCard = <FrenchRepublicanDateCard date={republicanDate}/>
        return (
            <ThemeProvider theme={theme}>
                {dateCard}
            </ThemeProvider>
        );
    } else {
        return (
            <ThemeProvider theme={theme}>
                <Skeleton height={450} width={450}/>
            </ThemeProvider>
        );
    }
}

export default App;