import {Observance} from "./Observance";
import {IntercalaryDay} from "./CalendarDay";

export class CalendarMonth {
    readonly name: String
    readonly days: number
    readonly intercalaryDays: ReadonlyArray<IntercalaryDayConfig>
    readonly observances: Map<number, ReadonlyArray<Observance>>


    constructor(
        name: String,
        days: number,
        intercalaryDays: ReadonlyArray<IntercalaryDayConfig>,
        observances: Map<number, ReadonlyArray<Observance>>
    ) {
        this.name = name;
        this.days = days;
        this.intercalaryDays = intercalaryDays
        this.observances = observances;
    }
}

export class IntercalaryDayConfig {
    readonly observances: ReadonlyArray<Observance>
    readonly observanceCalculator: (year: number) => boolean
    readonly isWeekday: boolean

    constructor(
        observances: ReadonlyArray<Observance>,
        observanceCalculator: (year: number) => boolean = () => true,
        isWeekday: boolean = false
    ) {
        this.observances = observances;
        this.observanceCalculator = observanceCalculator
        this.isWeekday = isWeekday
    }
}