import {CalendarDay, IntercalaryDay, MonthDay} from "../models/CalendarDay";
import React, {useEffect, useState} from "react";
import {Box, Card, CardMedia, Paper, Skeleton, Typography} from "@mui/material";
import {Observance} from "../models/Observance";

//TODO: dynamic image
//TODO: intercalary!
//TODO: transparent images
//TODO: grids instead of boxes
export function FrenchRepublicanDateCard(props: DateDisplayProps) {

    const date = props.date
    const observance = date.observances[0]
    if (date instanceof MonthDay) {
        return (
            <Card sx={{display: 'flex', width: 400, padding: 2}}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: 250,
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Typography>Aujourd'hui, c'est {date.weekday}</Typography>
                    <Box sx={{display: 'flex'}} alignItems="center">
                        <Typography component="div" variant="h1" color="primary">
                            {date.day}
                        </Typography>
                        <Box sx={{display: 'flex', flexDirection: 'column'}} px={2}>
                            <Typography variant="h5" color="secondary" component="div">
                                {date.month},
                            </Typography>
                            <Typography variant="h5" color="secondary" component="div">
                                {romanize(date.year)}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                {observance && <ObservanceCard observance={observance} key={observance.id}/>}
            </Card>
        );
    } else if (date instanceof IntercalaryDay) {
        return (
            <Paper>
                <p>{date.day}, {date.year}</p>
                <p>{date.observances.map(o => o.title).join(", ")}</p>
            </Paper>
        );
    } else {
        return <div/>
    }
}

type DateDisplayProps = {
    date: CalendarDay
}

function ObservanceCard(props: ObservanceCardProps) {

    const [imageUri, setImageUri] = useState<string|undefined>(`/static/images/${props.observance.id}.webp`)
    console.log(imageUri);
    console.log(props.observance.id)
    console.log(props)

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Card elevation={2}>
                {
                    (imageUri &&
                    <CardMedia
                        component="img"
                        sx={{width: 150, height: 150}}
                        image={imageUri}
                        alt={props.observance.id}
                        onError={() => setImageUri("/static/images/default.webp")}
                    />) || <Skeleton width={150} height={150}/>
                }
            </Card>
            <Typography variant="h6" pt={1} color="secondary"
                        fontFamily="Homemade Apple">{props.observance.title}</Typography>
        </Box>
    );
}

type ObservanceCardProps = {
    observance: Observance
}

function romanize(num: number) {
    const lookup: { [key: string]: number } = {
        M: 1000, CM: 900, D: 500, CD: 400, C: 100, XC: 90, L: 50, XL: 40, X: 10, IX: 9, V: 5, IV: 4, I: 1
    }
    let roman = '';
    for (const i in lookup) {
        while (num >= lookup[i]) {
            roman += i;
            num -= lookup[i];
        }
    }
    return roman;
}