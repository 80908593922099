import {CalendarDay, IntercalaryDay, MonthDay} from "./CalendarDay";
import {Observance} from "./Observance";
import {Calendar} from "./Calendar";
import {CalendarMonth, IntercalaryDayConfig} from "./CalendarMonth";

export class FrenchRepublicanCalendar implements Calendar {
    calendarDate(date: Date): CalendarDay {
        const rawDate = this.getRawDate(new Date(date.getFullYear(), date.getMonth(), date.getDate()))
        return this.getDate(rawDate)
    }

    private getRawDate(date: Date): RawDate {
        if (date < gregorianStart) {
            throw new Error("Invalid date: before start of selected calendar")
        }
        const diff = Math.abs(date.getTime() - gregorianStart.getTime());
        let days = Math.floor(diff / (1000 * 3600 * 24)) + this.calendarStart.day;
        let year = this.calendarStart.year;
        let daysInYear = this.daysInYear(year)
        let numberOfWeekdays = 0;
        while (days > daysInYear) {
            days -= daysInYear
            year += 1
            numberOfWeekdays += this.weekdaysInYear(year)
            daysInYear = this.daysInYear(year)
        }
        numberOfWeekdays += days
        return new RawDate(year, days, weekdays[(numberOfWeekdays % weekdays.length) - 1]) //0-based list
    }

    private weekdaysInYear(year: number): number {
        return months
            .map(m => this.weekdaysInMonth(m, year))
            .reduce((acc, curr) => acc + curr, 0)
    }

    private daysInYear(year: number): number {
        return months
            .map(m => this.daysInMonth(m, year))
            .reduce((acc, currentValue) => acc + currentValue, 0)
    }

    private getDate(rawDate: RawDate): CalendarDay {
        let days = rawDate.dayOfYear
        let monthIndex = 0
        let daysInCurrentMonth = this.daysInMonth(months[monthIndex], rawDate.year)
        while (days > daysInCurrentMonth) {
            days = days - daysInCurrentMonth;
            monthIndex = monthIndex + 1
            daysInCurrentMonth = this.daysInMonth(months[monthIndex], rawDate.year)
        }
        const month = months[monthIndex]
        if (days > month.days) {
            const intercalary = month.intercalaryDays.filter(d => d.observanceCalculator(rawDate.year))[days - month.days - 1]
            return new IntercalaryDay(
                rawDate.year,
                days,
                intercalary.observances,
            )
        } else {
            return new MonthDay(
                rawDate.year,
                month.name,
                days,
                rawDate.weekday,
                month.observances.get(days) || []
            )
        }
    }

    private weekdaysInMonth(month: CalendarMonth, year: number): number {
        const intercalary = month.intercalaryDays
            .filter(d => d.observanceCalculator(year))
            .filter(d => d.isWeekday)
            .length

        return month.days + intercalary
    }

    private daysInMonth(month: CalendarMonth, year: number): number {
        const intercalary = month.intercalaryDays.filter(d => d.observanceCalculator(year)).length
        return month.days + intercalary
    }

    private calendarStart = new MonthDay(1, months[0].name, 1, weekdays[0], months[0].observances.get(1) || [])

}

const gregorianStart: Date = new Date("1792-09-22")

const months: Array<CalendarMonth> = [
    new CalendarMonth("Vendémiaire", 30, [], new Map([
        [1,  [new Observance("grape", "Raisin")]],
        [2,  [new Observance("saffron", "Safran")]],
        [3,  [new Observance("chestnut", "Châtaigne")]],
        [4,  [new Observance("crocus", "Colchique")]],
        [5,  [new Observance("horse", "Cheval")]],
        [6,  [new Observance("impatiens", "Balsamine")]],
        [7,  [new Observance("carrot", "Carotte")]],
        [8,  [new Observance("amaranth", "Amaranthe")]],
        [9,  [new Observance("parsnip", "Panais")]],
        [10, [new Observance("vat", "Cuve")]],
        [11, [new Observance("potato", "Pomme de terre")]],
        [12, [new Observance("strawflower", "Immortelle")]],
        [13, [new Observance("winter_squash", "Potiron")]],
        [14, [new Observance("mignonette", "Réséda")]],
        [15, [new Observance("donkey", "Âne")]],
        [16, [new Observance("four_o_clock_flower", "Belle de nuit")]],
        [17, [new Observance("pumpkin", "Citrouille")]],
        [18, [new Observance("buckwheat", "Sarrasin")]],
        [19, [new Observance("sunflower", "Tournesol")]],
        [20, [new Observance("winepress", "Pressoir")]],
        [21, [new Observance("hemp", "Chanvre")]],
        [22, [new Observance("peach", "Pêche")]],
        [23, [new Observance("turnip", "Navet")]],
        [24, [new Observance("amaryllis", "Amaryllis")]],
        [25, [new Observance("ox", "Bœuf")]],
        [26, [new Observance("eggplant", "Aubergine")]],
        [27, [new Observance("chili_pepper", "Piment")]],
        [28, [new Observance("tomato", "Tomate")]],
        [29, [new Observance("barley", "Orge")]],
        [30, [new Observance("barrel", "Tonneau")]],
    ])),
    new CalendarMonth("Brumaire", 30, [], new Map([
        [1,  [new Observance("apple", "Pomme")]],
        [2,  [new Observance("celery", "Céleri")]],
        [3,  [new Observance("pear", "Poire")]],
        [4,  [new Observance("beet", "Betterave")]],
        [5,  [new Observance("goose", "Oie")]],
        [6,  [new Observance("heliotrope", "Héliotrope")]],
        [7,  [new Observance("fig", "Figue")]],
        [8,  [new Observance("black_salsify", "Scorsonère")]],
        [9,  [new Observance("chequer tree", "Alisier")]],
        [10, [new Observance("plow", "Charrue")]],
        [11, [new Observance("salsify", "Salsifis")]],
        [12, [new Observance("water_chestnut", "Mâcre")]],
        [13, [new Observance("jerusalem_artichoke", "Topinambour")]],
        [14, [new Observance("endive", "Endive")]],
        [15, [new Observance("turkey", "Dindon")]],
        [16, [new Observance("skirret", "Chervis")]],
        [17, [new Observance("watercress", "Cresson")]],
        [18, [new Observance("leadwort", "Dentelaire")]],
        [19, [new Observance("pomegranate", "Grenade")]],
        [20, [new Observance("harrow", "Herse")]],
        [21, [new Observance("baccharis", "Bacchante")]],
        [22, [new Observance("azerole", "Azerole")]],
        [23, [new Observance("madder", "Garance")]],
        [24, [new Observance("orange", "Orange")]],
        [25, [new Observance("pheasant", "Faisan")]],
        [26, [new Observance("pistachio", "Pistache")]],
        [27, [new Observance("tuberous_pea", "Macjonc")]],
        [28, [new Observance("quince", "Coing")]],
        [29, [new Observance("service_tree", "Cormier")]],
        [30, [new Observance("roller", "Rouleau")]],
    ])),
    new CalendarMonth("Frimaire", 30, [], new Map([
        [1,  [new Observance("rampion", "Raiponce")]],
        [2,  [new Observance("rutabaga", "Turneps")]],
        [3,  [new Observance("chicory", "Chicorée")]],
        [4,  [new Observance("medlar", "Nèfle")]],
        [5,  [new Observance("pig", "Cochon")]],
        [6,  [new Observance("corn_salad", "Mâche")]],
        [7,  [new Observance("cauliflower", "Chou-fleur")]],
        [8,  [new Observance("honey", "Miel")]],
        [9,  [new Observance("juniper_berries", "Genièvre")]],
        [10, [new Observance("pickaxe", "Pioche")]],
        [11, [new Observance("wax", "Cire")]],
        [12, [new Observance("horseradish", "Raifort")]],
        [13, [new Observance("cedar_tree", "Cèdre")]],
        [14, [new Observance("fir", "Sapin")]],
        [15, [new Observance("roe_deer", "Chevreuil")]],
        [16, [new Observance("gorse", "Ajonc")]],
        [17, [new Observance("cypress_tree", "Cyprès")]],
        [18, [new Observance("ivy", "Lierre")]],
        [19, [new Observance("savin_juniper", "Sabine")]],
        [20, [new Observance("grub_hoe", "Hoyau")]],
        [21, [new Observance("sugar_maple", "Érable à sucre")]],
        [22, [new Observance("heather", "Bruyère")]],
        [23, [new Observance("reed_plant", "Roseau")]],
        [24, [new Observance("sorrel", "Oseille")]],
        [25, [new Observance("cricket", "Grillon")]],
        [26, [new Observance("pine_nut", "Pignon")]],
        [27, [new Observance("cork", "Liège")]],
        [28, [new Observance("truffle", "Truffe")]],
        [29, [new Observance("olive", "Olive")]],
        [30, [new Observance("shovel", "Pelle")]],
    ])),
    new CalendarMonth("Nivôse", 30, [], new Map([
        [1,  [new Observance("peat", "Tourbe")]],
        [2,  [new Observance("coal", "Houille")]],
        [3,  [new Observance("bitumen", "Bitume")]],
        [4,  [new Observance("sulfur", "Soufre")]],
        [5,  [new Observance("dog", "Chien")]],
        [6,  [new Observance("lava", "Lave")]],
        [7,  [new Observance("topsoil", "Terre végétal")]],
        [8,  [new Observance("manure", "Fumier")]],
        [9,  [new Observance("saltpeter", "Salpêtre")]],
        [10, [new Observance("flail", "Fléau")]],
        [11, [new Observance("granite", "Granit")]],
        [12, [new Observance("clay", "Argile")]],
        [13, [new Observance("slate", "Ardoise")]],
        [14, [new Observance("sandstone", "Grès")]],
        [15, [new Observance("rabbit", "Lapin")]],
        [16, [new Observance("flint", "Silex")]],
        [17, [new Observance("marlstone", "Marne")]],
        [18, [new Observance("linestone", "Pierre à chaux")]], //limestone
        [19, [new Observance("marble", "Marbre")]],
        [20, [new Observance("winnowing_basket", "Van")]],
        [21, [new Observance("gypsum", "Pierre à plâtre")]],
        [22, [new Observance("salt", "Sel")]],
        [23, [new Observance("iron", "Fer")]],
        [24, [new Observance("copper", "Cuivre")]],
        [25, [new Observance("cat", "Chat")]],
        [26, [new Observance("tin", "Étain")]],
        [27, [new Observance("lead", "Plomb")]],
        [28, [new Observance("zinc", "Zinc")]],
        [29, [new Observance("mercury", "Mercure")]],
        [30, [new Observance("sieve", "Crible")]],
    ])),
    new CalendarMonth("Pluviôse", 30, [], new Map([
        [1,  [new Observance("spurge_laurel", "Lauréole")]],
        [2,  [new Observance("moss", "Mousse")]],
        [3,  [new Observance("butchers_broom", "Fragon")]],
        [4,  [new Observance("snowdrop", "Perce-neige")]],
        [5,  [new Observance("bull", "Taureau")]],
        [6,  [new Observance("laurustinus", "Laurier-thym")]],
        [7,  [new Observance("tinder_polypore", "Amadouvier")]],
        [8,  [new Observance("february_daphne", "Mézéréon")]],
        [9,  [new Observance("poplar", "Peuplier")]],
        [10, [new Observance("hatchet", "Coignée")]],
        [11, [new Observance("hellebore", "Ellébore")]],
        [12, [new Observance("broccoli", "Brocoli")]],
        [13, [new Observance("bay_laurel", "Laurier")]],
        [14, [new Observance("filbert", "Avelinier")]],
        [15, [new Observance("cow", "Vache")]],
        [16, [new Observance("boxtree", "Buis")]],
        [17, [new Observance("lichen", "Lichen")]],
        [18, [new Observance("yew_tree", "If")]],
        [19, [new Observance("lungwort", "Pulmonaire")]],
        [20, [new Observance("billhook", "Serpette")]],
        [21, [new Observance("pennycress", "Thlaspi")]],
        [22, [new Observance("rose_daphne", "Thymèle")]],
        [23, [new Observance("couch_grass", "Chiendent")]],
        [24, [new Observance("knotgrass", "Traînasse")]],
        [25, [new Observance("hare", "Lièvre")]],
        [26, [new Observance("woad", "Guède")]],
        [27, [new Observance("hazel_tree", "Noisetier")]],
        [28, [new Observance("cyclamen", "Cyclamen")]],
        [29, [new Observance("celandine", "Chélidoine")]],
        [30, [new Observance("sleigh", "Traîneau")]],
    ])),
    new CalendarMonth("Ventôse", 30, [], new Map([
        [1,  [new Observance("coltsfoot", "Tussilage")]],
        [2,  [new Observance("dogwood", "Cornouiller")]],
        [3,  [new Observance("hoary_stock", "Violier")]],
        [4,  [new Observance("privet", "Troène")]],
        [5,  [new Observance("billygoat", "Bouc")]],
        [6,  [new Observance("wild_ginger", "Asaret")]],
        [7,  [new Observance("italian_buckthorn", "Alaterne")]],
        [8,  [new Observance("violet", "Violette")]],
        [9,  [new Observance("goat_willow", "Marceau")]],
        [10, [new Observance("spade", "Bêche")]],
        [11, [new Observance("narcissus", "Narcisse")]],
        [12, [new Observance("elm", "Orme")]],
        [13, [new Observance("fumitory", "Fumeterre")]],
        [14, [new Observance("hedge_mustard", "Vélar")]],
        [15, [new Observance("goat", "Chèvre")]],
        [16, [new Observance("spinach", "Épinard")]],
        [17, [new Observance("doronicum", "Doronic")]],
        [18, [new Observance("pimpernel", "Mouron")]],
        [19, [new Observance("chervil", "Cerfeuil")]],
        [20, [new Observance("twine", "Cordeau")]],
        [21, [new Observance("mandrake", "Mandragore")]],
        [22, [new Observance("parsley", "Persil")]],
        [23, [new Observance("scurvy_grass", "Cochléaria")]],
        [24, [new Observance("daisy", "Pâquerette")]],
        [25, [new Observance("tuna", "Thon")]],
        [26, [new Observance("dandelion", "Pissenlit")]],
        [27, [new Observance("wood_anemone", "Sylvie")]],
        [28, [new Observance("maidenhair_fern", "Capillaire")]],
        [29, [new Observance("ash_tree", "Frêne")]],
        [30, [new Observance("dibber", "Plantoir")]],
    ])),
    new CalendarMonth("Germinal", 30, [], new Map([
        [1,  [new Observance("primrose", "Primevère")]],
        [2,  [new Observance("plane_tree", "Platane")]],
        [3,  [new Observance("asparagus", "Asperge")]],
        [4,  [new Observance("tulip", "Tulipe")]],
        [5,  [new Observance("hen", "Poule")]],
        [6,  [new Observance("chard", "Bette")]],
        [7,  [new Observance("birch", "Bouleau")]],
        [8,  [new Observance("daffodil", "Jonquille")]],
        [9,  [new Observance("alder", "Aulne")]],
        [10, [new Observance("hatchery", "Couvoir")]],
        [11, [new Observance("periwinkle", "Pervenche")]],
        [12, [new Observance("hornbeam", "Hornbeam")]],
        [13, [new Observance("morel", "Morille")]],
        [14, [new Observance("beech_tree", "Hêtre")]],
        [15, [new Observance("bee", "Abeille")]],
        [16, [new Observance("lettuce", "Laitue")]],
        [17, [new Observance("larch", "Mélèze")]],
        [18, [new Observance("hemlock", "Ciguë")]],
        [19, [new Observance("radish", "Radis")]],
        [20, [new Observance("hive", "Ruche")]],
        [21, [new Observance("judas_tree", "Gainier")]],
        [22, [new Observance("romaine", "Romaine")]],
        [23, [new Observance("horse_chestnut", "Marronnier")]],
        [24, [new Observance("arugula", "Roquette")]],
        [25, [new Observance("pigeon", "Pigeon")]],
        [26, [new Observance("lilac", "Lilas")]],
        [27, [new Observance("anemone", "Anémone")]],
        [28, [new Observance("pansy", "Pensée")]],
        [29, [new Observance("bilberry", "Myrtille")]],
        [30, [new Observance("grafting_knife", "Greffoir")]],
    ])),
    new CalendarMonth("Floréal", 30, [], new Map([
        [1,  [new Observance("rose", "Rose")]],
        [2,  [new Observance("oak_tree", "Chêne")]],
        [3,  [new Observance("fern", "Fougère")]],
        [4,  [new Observance("hawthorn", "Aubépine")]],
        [5,  [new Observance("nightingale", "Rossignal")]],
        [6,  [new Observance("columbine", "Ancolie")]],
        [7,  [new Observance("lily_of_the_valley", "Muguet")]],
        [8,  [new Observance("button_mushroom", "Champignon")]],
        [9,  [new Observance("hyacinth", "Hyacinthe")]],
        [10, [new Observance("rake", "Rateau")]],
        [11, [new Observance("rhubarb", "Rhubarbe")]],
        [12, [new Observance("sainfoin", "Sainfoin")]],
        [13, [new Observance("wallflower", "Bâton d'or")]],
        [14, [new Observance("fan_palm", "Chamerisier")]],
        [15, [new Observance("silkworm", "Ver à soie")]],
        [16, [new Observance("comfrey", "Consoude")]],
        [17, [new Observance("salad_burnet", "Pimprenelle")]],
        [18, [new Observance("alyssum", "Corbeille d'or")]],
        [19, [new Observance("orache", "Arroche")]],
        [20, [new Observance("garden_hoe", "Sarcloir")]],
        [21, [new Observance("sea lavender", "Statice")]],
        [22, [new Observance("fritillary", "Fritillaire")]],
        [23, [new Observance("borage", "Bourrache")]],
        [24, [new Observance("valerian", "Valériane")]],
        [25, [new Observance("carp", "Carpe")]],
        [26, [new Observance("spindle", "Fusain")]],
        [27, [new Observance("chive", "Civette")]],
        [28, [new Observance("bugloss", "Buglosse")]],
        [29, [new Observance("charlock", "Sénevé")]],
        [30, [new Observance("shepherds_crook", "Houlette")]],
    ])),
    new CalendarMonth("Prairial", 30, [], new Map([
        [1,  [new Observance("alfalfa", "Luzerne")]],
        [2,  [new Observance("daylily", "Hémérocalle")]],
        [3,  [new Observance("clover", "Tréfle")]],
        [4,  [new Observance("angelica", "Angélique")]],
        [5,  [new Observance("duck", "Canard")]],
        [6,  [new Observance("lemon_balm", "Mélisse")]],
        [7,  [new Observance("oat_grass", "Fromental")]],
        [8,  [new Observance("martagnon_lily", "Martagon")]],
        [9,  [new Observance("wild_thyme", "Serpolet")]],
        [10, [new Observance("scythe", "Faux")]],
        [11, [new Observance("strawberry", "Fraise")]],
        [12, [new Observance("woundwort", "Bétaine")]],
        [13, [new Observance("pea", "Pois")]],
        [14, [new Observance("acacia", "Acacia")]],
        [15, [new Observance("quail", "Caille")]],
        [16, [new Observance("carnation", "Œillet")]],
        [17, [new Observance("elderberry", "Sureau")]],
        [18, [new Observance("poppy", "Pavot")]],
        [19, [new Observance("lime", "Tilluel")]],
        [20, [new Observance("pitchfork", "Fourche")]],
        [21, [new Observance("cornflower", "Barbeau")]],
        [22, [new Observance("chamomile", "Camomille")]],
        [23, [new Observance("honeysuckle", "Chèvrefleur")]],
        [24, [new Observance("bedstraw", "Caille-lait")]],
        [25, [new Observance("tench", "Tanche")]],
        [26, [new Observance("jasmine", "Jasmin")]],
        [27, [new Observance("verbena", "Verveine")]],
        [28, [new Observance("thyme", "Thym")]],
        [29, [new Observance("peony", "Pivaine")]],
        [30, [new Observance("hand_cart", "Chariot")]],
    ])),
    new CalendarMonth("Messidor", 30, [], new Map([
        [1,  [new Observance("rye", "Siegle")]],
        [2,  [new Observance("oat", "Ovaine")]],
        [3,  [new Observance("onion", "Oignon")]],
        [4,  [new Observance("speedwell", "Véronique")]],
        [5,  [new Observance("mule", "Mulet")]],
        [6,  [new Observance("rosemary", "Romarin")]],
        [7,  [new Observance("cucumber", "Concombre")]],
        [8,  [new Observance("shallot", "Échalote")]],
        [9,  [new Observance("wormwood", "Absinthe")]],
        [10, [new Observance("sickle", "Faucille")]],
        [11, [new Observance("coriander", "Coriandre")]],
        [12, [new Observance("artichoke", "Artichaut")]],
        [13, [new Observance("clove", "Girofle")]],
        [14, [new Observance("lavender", "Lavande")]],
        [15, [new Observance("chamois", "Chamois")]],
        [16, [new Observance("tobacco", "Tabac")]],
        [17, [new Observance("red_currant", "Grosseille")]],
        [18, [new Observance("sweet_pea", "Gesse")]],
        [19, [new Observance("cherry", "Cerise")]],
        [20, [new Observance("livestock_pen", "Parc")]],
        [21, [new Observance("mint", "Menthe")]],
        [22, [new Observance("cumin", "Cumin")]],
        [23, [new Observance("bean", "Haricot")]],
        [24, [new Observance("alkanet", "Orcanète")]],
        [25, [new Observance("guinea_fowl", "Pintade")]],
        [26, [new Observance("sage", "Sauge")]],
        [27, [new Observance("garlic", "Ail")]],
        [28, [new Observance("vetch", "Vesce")]],
        [29, [new Observance("wheat", "Blé")]],
        [30, [new Observance("shawm", "Chalemie")]],
    ])),
    new CalendarMonth("Thermidor", 30, [], new Map([
        [1,  [new Observance("spelt", "Épeautre")]],
        [2,  [new Observance("common_muillein", "Bouillion blanc")]],
        [3,  [new Observance("melon", "Melon")]],
        [4,  [new Observance("ryegrass", "Ivraie")]],
        [5,  [new Observance("ram", "Bélier")]],
        [6,  [new Observance("horsetail", "Prêle")]],
        [7,  [new Observance("mugwort", "Armoise")]],
        [8,  [new Observance("safflower", "Carthame")]],
        [9,  [new Observance("blackberry", "Mûre")]],
        [10, [new Observance("watering_can", "Arrosoir")]],
        [11, [new Observance("switchgrass", "Panic")]],
        [12, [new Observance("glasswort", "Salicorne")]],
        [13, [new Observance("apricot", "Abricot")]],
        [14, [new Observance("basil", "Basilic")]],
        [15, [new Observance("ewe", "Brebis")]],
        [16, [new Observance("marshmallow", "Guimauve")]],
        [17, [new Observance("flax", "Lin")]],
        [18, [new Observance("almond", "Amande")]],
        [19, [new Observance("gentian", "Gentiane")]],
        [20, [new Observance("lock", "Écluse")]],
        [21, [new Observance("carline_thistle", "Carline")]],
        [22, [new Observance("caper", "Câprier")]],
        [23, [new Observance("lentil", "Lentille")]],
        [24, [new Observance("inula", "Aunée")]],
        [25, [new Observance("otter", "Loutre")]],
        [26, [new Observance("myrtle", "Myrte")]],
        [27, [new Observance("rapeseed", "Colza")]],
        [28, [new Observance("lupin", "Lupin")]],
        [29, [new Observance("cotton", "Coton")]],
        [30, [new Observance("windmill", "Moulin")]],
    ])),
    new CalendarMonth("Fructidor", 30, [], new Map([
        [1,  [new Observance("plum", "Prune")]],
        [2,  [new Observance("millet", "Millet")]],
        [3,  [new Observance("puffball", "Lycoperdon")]],
        [4,  [new Observance("six_row_barley", "Escourgeon")]],
        [5,  [new Observance("salmon", "Saumon")]],
        [6,  [new Observance("tuberose", "Tubérose")]],
        [7,  [new Observance("winter_barley", "Sucrion")]],
        [8,  [new Observance("dogbone", "Apocyn")]],
        [9,  [new Observance("licorice", "Réglisse")]],
        [10, [new Observance("ladder", "Échelle")]],
        [11, [new Observance("watermelon", "Pastèque")]],
        [12, [new Observance("fennel", "Fenouil")]],
        [13, [new Observance("barberry", "Épine vinette")]],
        [14, [new Observance("walnut", "Noix")]],
        [15, [new Observance("trout", "Truite")]],
        [16, [new Observance("lemon", "Citron")]],
        [17, [new Observance("teasel", "Cardère")]],
        [18, [new Observance("buckthorn", "Nerprun")]],
        [19, [new Observance("marigold", "Tagette")]],
        [20, [new Observance("harvesting", "Hotte")]], //TODO better translation
        [21, [new Observance("wild_rose", "Églantier")]],
        [22, [new Observance("hazelnut", "Noisette")]],
        [23, [new Observance("hops", "Houblon")]],
        [24, [new Observance("sorghum", "Sorgho")]],
        [25, [new Observance("crayfish", "Écrevisse")]],
        [26, [new Observance("bitter_orange", "Bigorade")]],
        [27, [new Observance("goldenrod", "Verge d'or")]],
        [28, [new Observance("corn", "Maïs")]],
        [29, [new Observance("sweet_chestnut", "Marron")]],
        [30, [new Observance("basket", "Panier")]],
    ])),
    new CalendarMonth("Jours complémentaires", 0,[
        new IntercalaryDayConfig([new Observance("virtue", "La Fête de la Vertu")]),
        new IntercalaryDayConfig([new Observance("talent", "La Fête du Génie")]),
        new IntercalaryDayConfig([new Observance("labor", "La Fête du Travail")]),
        new IntercalaryDayConfig([new Observance("convictions", "La Fête de l’Opinion")]),
        new IntercalaryDayConfig([new Observance("virtue", "La Fête des Récomponses")]),
        new IntercalaryDayConfig(
            [new Observance("revolution", "La Fête de la Révolution")],
            (year: number) => year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)
        )
    ], new Map())
]

const weekdays = [
    "Primidi",
    "Duodi",
    "Tridi",
    "Quartidi",
    "Quintidi",
    "Sextidi",
    "Septidi",
    "Octidi",
    "Nonidi",
    "Décadi"
]

class RawDate {
    readonly year: number
    readonly dayOfYear: number
    readonly weekday: String


    constructor(year: number, dayOfYear: number, weekday: String) {
        this.year = year;
        this.dayOfYear = dayOfYear;
        this.weekday = weekday;
    }
}