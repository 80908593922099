import {Observance} from "./Observance";

export interface CalendarDay {
    readonly year: number,
    readonly observances: ReadonlyArray<Observance>
}

export class MonthDay implements CalendarDay {

    constructor(year: number, month: String, day: number, weekday: String, observances: ReadonlyArray<Observance>) {
        this.year = year;
        this.month = month;
        this.day = day;
        this.weekday = weekday;
        this.observances = observances;
    }

    readonly year: number
    readonly month: String
    readonly day: number
    readonly weekday: String
    readonly observances: ReadonlyArray<Observance>
}

export class IntercalaryDay implements CalendarDay {
    readonly year: number
    readonly day: number
    readonly observances: ReadonlyArray<Observance>


    constructor(year: number, day: number, observances: ReadonlyArray<Observance>) {
        this.year = year;
        this.day = day;
        this.observances = observances;
    }
}